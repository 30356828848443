import React from "react";
import { Link } from "react-router-dom";
import { RouteNavigation } from "../../Routes";

function Footer() {
  return (
    <footer className="text-white body-font bg-[#004e4a]">
      <div className="container px-5 py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
        <div className="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left md:mt-0 mt-10">
          <img
            src="/images/logo.png"
            alt="logo"
            className="w-20 bg-white rounded-full m-auto"
            width={100}
            height={100}
          />
        </div>
        <div className="flex-grow flex flex-wrap md:pr-20 -mb-10 md:text-left text-center order-first">
          <div className="lg:w-1/4 md:w-1/2 w-full px-4">
            <h2 className="title-font font-bold tracking-widest text-lg mb-3">
              Get in Touch
            </h2>
            <nav className="list-none mb-10 text-sm">
              <li>
                <span>
                  Email : <br />
                  <a
                    href="mailto:orginfoodstradelinks@gmail.com"
                    class="hover:underline hover:text-blue-500"
                  >
                    orginfoodstradelinks@gmail.com
                  </a>
                </span>
              </li>
              <li>
                <span>
                  Mobile : <br />
                  <a
                    href="tel:+919778059596"
                    class="hover:underline hover:text-blue-500"
                  >
                    +919778059596
                  </a>
                </span>
              </li>
            </nav>
          </div>
          <div className="lg:w-1/4 md:w-1/2 w-full px-4">
            <h2 className="title-font font-bold tracking-widest text-lg mb-3">
              Good Inside
            </h2>
            <nav className="list-none mb-10">
              <li>
                <Link to={RouteNavigation.about}>
                  <span>About Us</span>
                </Link>
              </li>
              <li>
                <Link to={RouteNavigation.contact}>
                  <span>Contact Us</span>
                </Link>
              </li>
            </nav>
          </div>
          <div className="lg:w-1/4 md:w-1/2 w-full px-4">
            <h2 className="title-font font-bold tracking-widest text-lg mb-3">
              Quick Links
            </h2>
            <nav className="list-none mb-10">
              <li>
                <span>Terms & Conditions</span>
              </li>
              <li>
                <span>Shipping & Delivery Policies</span>
              </li>
              <li>
                <span>Privacy Policy</span>
              </li>
            </nav>
          </div>
          <div className="lg:w-1/4 md:w-1/2 w-full px-4">
            <h2 className="title-font font-bold tracking-widest text-lg mb-3">
              Follow Us On
            </h2>
            <nav className="list-none mb-10">
              <li>
                <span>Facebook</span>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/goodinside.in?igsh=MTlvdWc5ZW94YWkxeQ=="
                  target="_blank"
                >
                  <span>Instagram</span>
                </a>
              </li>
            </nav>
          </div>
        </div>
      </div>
      <div className="bg-gray-100">
        <div className="container mx-auto py-4 px-5 flex flex-col md:flex-row text-center md:justify-between">
          <p className="text-gray-500 text-sm">
            Copyright © 2024 Good Inside - All rights reserved.
          </p>
          <p className="text-gray-500 text-sm">
            <b>Designed and Developed by :</b> Akhil Anitha Gregory and{" "}
            <a
              className="underline"
              href="https://logixassociates.com/"
              target="_blank"
            >
              Logix Associates
            </a>{" "}
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
