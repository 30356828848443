const ContactUs = () => {
  return (
    <div className="container my-12 mx-auto px-2 md:px-4">
      <section className="mb-32">
        <div className="flex justify-center bg-gray-400 w-full py-6 mb-5">
          <div className="text-center md:max-w-xl lg:max-w-3xl">
            <h2 className="px-6 text-3xl font-semibold">Contact us</h2>
          </div>
        </div>
        <div className="flex flex-wrap">
          <div className="flex flex-col">
            <div className="mb-12 w-full">
              <div className="flex items-start">
                <div className="shrink-0">
                  <div className="inline-block rounded-md bg-teal-400-100 p-4 text-teal-700">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      className="h-6 w-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M14.25 9.75v-4.5m0 4.5h4.5m-4.5 0l6-6m-3 18c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 014.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 00-.38 1.21 12.035 12.035 0 007.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 011.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 01-2.25 2.25h-2.25z"
                      />
                    </svg>
                  </div>
                </div>
                <div className="ml-6 grow">
                  <p className="mb-2 font-bold">Address</p>
                  <p className="text-neutral-500 ">ORGIN FOODS TRADE LINKS</p>
                  <p className="text-neutral-500 ">
                    TC 100/930-1, STATION KADAVU KAZHAKUTAM P O,
                  </p>
                  <p className="text-neutral-500 ">
                    TRIVANDRUM, KERALA, 695582
                  </p>
                </div>
              </div>
            </div>
            <div className="mb-12 w-full">
              <div className="flex items-start">
                <div className="shrink-0">
                  <div className="inline-block rounded-md bg-teal-400-100 p-4 text-teal-700">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      className="h-6 w-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M14.25 9.75v-4.5m0 4.5h4.5m-4.5 0l6-6m-3 18c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 014.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 00-.38 1.21 12.035 12.035 0 007.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 011.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 01-2.25 2.25h-2.25z"
                      />
                    </svg>
                  </div>
                </div>
                <div className="ml-6 grow">
                  <p className="mb-2 font-bold ">For Enquiry</p>
                  <p className="text-neutral-500 ">
                    orginfoodstradelinks@gmail.com
                  </p>
                  <p className="text-neutral-500 ">+91 9778059596</p>
                </div>
              </div>
            </div>
            <div className="mb-12 w-full">
              <div className="align-start flex">
                <div className="shrink-0">
                  <div className="inline-block rounded-md bg-teal-400-100 p-4 text-teal-700">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      className="h-6 w-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M14.25 9.75v-4.5m0 4.5h4.5m-4.5 0l6-6m-3 18c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 014.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 00-.38 1.21 12.035 12.035 0 007.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 011.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 01-2.25 2.25h-2.25z"
                      />
                    </svg>
                  </div>
                </div>
                <div className="ml-6 grow">
                  <p className="mb-2 font-bold ">Customer Help Line </p>
                  <p className="text-neutral-500 ">
                    customersupport@goodinside.in
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactUs;
