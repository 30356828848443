import "./App.css";
import { Header } from "./components";
import { Footer } from "./components";
import GenericRoutes from "./Routes";

function App() {
  return (
    <div className="App">
      <Header />
      <GenericRoutes />
      <Footer />
    </div>
  );
}

export default App;
