import React from "react";
import { Link } from "react-router-dom";
import { LuUser2, LuHeart } from "react-icons/lu";
import { IoBagOutline } from "react-icons/io5";
import { FaMagnifyingGlass } from "react-icons/fa6";

function Header() {
  return (
    <header className="text-gray-600 body-font">
      <div className="bg-[#004e4a] text-white">
        <marquee>🌟 Free Shipping All Over India 🌟</marquee>
      </div>
      <div className="container mx-auto flex flex-wrap py-2 flex-col md:flex-row items-center">
        <img
          src="/images/logo.png"
          alt="logo"
          className="w-20"
          width={100}
          height={100}
        />
        <nav className="md:ml-auto md:mr-auto flex flex-wrap items-center text-base justify-center">
          <Link to={"/"}>
            <span className="mr-5 text-gray-900 font-semibold">Home</span>
          </Link>
          <span className="mr-5 text-gray-900 font-semibold">Best Sellers</span>
          <span className="mr-5 text-gray-900 font-semibold">My Account</span>
        </nav>
        <div className="inline-flex items-center border-0 py-1 px-3 mt-4 md:mt-0 gap-5">
          <div>
            <FaMagnifyingGlass size="20px" />
          </div>
          <div>
            <LuUser2 size="20px" />
          </div>
          <div>
            <LuHeart size="20px" />
          </div>
          <div>
            <IoBagOutline size="20px" />
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
