import { Route, Routes } from "react-router-dom";
import * as Page from "./pages";

export const RouteNavigation = {
  home: "/",
  productDetail: "/:id",
  about: "/about",
  contact: "/contact-us",
};

const GenericRoutes = () => {
  return (
    <Routes>
      <Route path={RouteNavigation.home} element={<Page.Home />} />
      <Route
        path={RouteNavigation.productDetail}
        element={<Page.ProductDetails />}
      />
      <Route path={RouteNavigation.about} element={<Page.About />} />
      <Route path={RouteNavigation.contact} element={<Page.ContactUs />} />
    </Routes>
  );
};
export default GenericRoutes;
