import React from "react";
import { Products } from "../../lib";
import { Link } from "react-router-dom";

export default function Home() {
  const ProductGrid = () => {
    return (
      <div className="grid grid-cols-2 sm:grid-cols-3">
        {Products?.map((product, index) => (
          <Link key={product.id} to={`${product.id}`}>
            <div
              key={index + 1}
              className="border-[1px] border-gray-200  p-4 bg-white w-full h-full"
            >
              <div className="relative w-full overflow-hidden">
                <img
                  src={product.imageSrc}
                  alt={product.imageAlt}
                  width={300}
                  height={100}
                  className="transform hover:scale-125 transition-transform duration-1000"
                />
              </div>
              <span className="font-bold text-lg">{product.name}</span>
              <br />
              <span className="font-semibold text-sm text-gray-400">
                {product.flavour}
              </span>
              <div className="border-[1px] border-orange-400 p-2 bg-orange-100 rounded-lg">
                {product.Quantity}
              </div>
              <span>
                {" "}
                <del className="text-gray-700 hidden">
                  Rs. {product.discountPrice}
                </del>{" "}
                <span className="text-orange-400">
                  {" "}
                  Rs. {product.salePrice}
                </span>{" "}
              </span>
              <button className="text-center bg-orange-400 w-full text-white p-2 rounded-2xl mt-2">
                Add to cart
              </button>
            </div>
          </Link>
        ))}
      </div>
    );
  };
  return (
    <div>
      <img src="./images/banner-01.png" alt="banner-01" className="w-full" />
      <div className="bg-yellow-100 md:p-4 py-4">
        <div className="max-w-[1024px] m-auto">
          <div className="pb-5">
            <div className="flex items-center">
              <hr className="flex-grow border-t border-gray-300" />
              <span className="px-4 text-center text-xl font-semibold">
                🌟 Best Sellers 🌟
              </span>
              <hr className="flex-grow border-t border-gray-300" />
            </div>
          </div>
          <ProductGrid />
        </div>
      </div>
      <div className="max-w-[1024px] m-auto pb-5">
        <div className="py-5">
          <div className="flex items-center">
            <hr className="flex-grow border-t border-gray-300" />
            <span className="px-4 text-center text-xl font-semibold">
              🌟 Our Specialities 🌟
            </span>
            <hr className="flex-grow border-t border-gray-300" />
          </div>
        </div>
        <img
          src="./images/specialities.jpg"
          alt="specialities"
          className="w-full"
        />
      </div>
    </div>
  );
}
