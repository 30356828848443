const AboutUs = () => {
  return (
    <section class="text-gray-600 body-font text-center py-5 bg-gray-200">
      <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
        About Good Inside
      </h1>
      <div className="max-w-[1024px] m-auto">
        <h1>ORGIN FOODS TRADE LINKS</h1>
        <p class="mb-8 leading-relaxed">
          TC 100/930-1, STATION KADAVU KAZHAKUTAM P O, TRIVANDRUM, KERALA,
          695582
        </p>
        <p>
          MOB :{" "}
          <a
            href="tel:+919778059596"
            class="hover:underline hover:text-blue-500"
          >
            +919778059596
          </a>
        </p>
        <p>
          Email :{" "}
          <a
            href="mailto:orginfoodstradelinks@gmail.com"
            class="hover:underline hover:text-blue-500"
          >
            orginfoodstradelinks@gmail.com
          </a>
        </p>
        <p>
          Consumer Help Line :{" "}
          <a
            href="mailto:customersupport@goodinside.in"
            class="hover:underline hover:text-blue-500"
          >
            customersupport@goodinside.in
          </a>
        </p>
      </div>
    </section>
  );
};

export default AboutUs;
